@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import "config";
@import "animations";

*,
*::before,
*::after {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba($uiBlackColor, 0);
  scrollbar-color: $uiNeutralColor $uiBorderColor;
  scrollbar-width: thin;
  box-sizing: border-box;
  outline: none !important;
  font-family: $uiFontFamily !important;
  text-decoration-thickness: 1px !important;
  text-underline-position: under !important;
  vertical-align: middle;
  user-select: text;
}

*::before,
*::after {
  pointer-events: none !important;
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: $uiNeutralColor;
  &:active {
    background-color: $uiHighlightColor;
  }
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: $uiBorderColor;
}

*:disabled,
*[disabled],
.disabled {
  opacity: $uiComponentDisabledOpacity !important;
  filter: grayscale($uiComponentDisabledOpacity) !important;
  *:disabled,
  *[disabled],
  .disabled {
    opacity: 1 !important;
    filter: none !important;
  }
  &,
  * {
    &,
    &::before,
    &::after {
      pointer-events: none;
    }
  }
}

*::placeholder {
  color: $uiPlaceholderColor;
}

html {
  height: 100%;
  font-size: 16px !important;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: $uiBackgroundColor;
  font-weight: normal;
  text-align: left;
  text-size-adjust: none;
  &[data-desktop] {
    min-width: 1024px;
    overflow: auto;
    font-size: $uiDesktopFontSize;
  }
  &[data-mobile] {
    font-size: $uiMobileFontSize;
  }
  &[data-modal] {
    overflow: hidden;
  }
  &[data-navbar] {
    #root {
      padding-left: 4.25rem;
    }
  }
}

label {
  margin: 0;
}

input,
textarea,
select,
button {
  text-size-adjust: none;
}

input {
  user-select: text !important;
  &::-webkit-search-cancel-button {
    cursor: pointer;
  }
}

button,
[role=button] {
  * {
    user-select: none;
  }
}

button,
[role=button] {
  * {
    user-select: none;
  }
}

a {
  color: $uiHighlightColor;
  &:hover {
    color: $uiDarkColor;
  }
  &,
  &:not([href]):not([class]) {
    text-decoration: underline;
    cursor: pointer;
  }
  &:not([href]):not([class]) {
    color: $uiDarkColor;
    &:hover {
      color: $uiHighlightColor;
    }
  }
}

svg + span,
span + svg {
  margin-left: 0.3em;
}

pre {
  margin: 0;
  font-size: 1em;
  white-space: pre-wrap;
}

#root {
  flex: auto;
  max-width: 100%;
}

.introjs {
  &-overlay {
    will-change: opacity;
  }
}

[data-wait] {
  animation: rotate 2s linear infinite;
}

#portal {
  .modal {
    &-backdrop {
      &:not(:first-child) {
        display: none;
      }
    }
    &:not(:last-child) {
      z-index: 1000;
      overflow: hidden;
    }
  }
}

.intercom {
  &-lightweight-app {
    z-index: 1049 !important;
  }
}

[data-edit-document] {
  .intercom {
    &-lightweight-app {
      display: none !important;
    }
  }
}

@media (min-width: $menuFullWidthMinViewport) {
  body {
    &[data-navbar] {
      #root {
        padding-left: $menuFullWidth;
      }
    }
  }
}
